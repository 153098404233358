.hoox-restore {
    background-color: #FAFAFA;
    max-height: 578px;
    padding: 48px 0;
 }
.hoox-restore__title {
    text-align: center;
    font-size: 28px;
    font-family: 'Monotype Century Old Style';
    font-weight: 400;
    color: #414D57;
    margin-bottom: 8px;
}
.hoox-restore__subtitle {
    text-align: center;
}
.hoox-restore__items {
    margin-top: 32px;
    display: flex;
    flex-direction: column;

}
.hoox-restore__item {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    margin-bottom: 24px;
}
.hoox-benefit__icon {
    max-width: 56px;
    width: 100%;
    height: 56px;
}
.hoox-benefit__icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.hoox-benefit__title {
    font-size: 20px;
    line-height: 26px;
    color: #414D57;
    margin-bottom: 8px;
    font-family: 'Monotype Century Old Style';
}
.hoox-benefit__text {
    font-size: 16px;
    line-height: 24px;
    color: #414D57;
    font-weight: 400;
    max-width: 257px;
}

@media(min-width: 768px) {
    .hoox-restore {
        max-height: 453px;
        padding: 80px 0;
     }
     .hoox-restore__title{
        font-size: 40px;
     }

     .hoox-restore__items {
        margin-top: 48px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 60px;
    }

    .hoox-restore__item {
        max-width: 360px;
        width: 100%;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 0px;
    }
    .hoox-benefit__title{
        text-align: center;
    }

    .hoox-benefit__text {
        max-width: 100%;
        text-align: center;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait){
    .hoox-restore {
        max-height: unset;
    }
}